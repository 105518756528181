import "reflect-metadata";
import { initializeFirebaseService } from "./initFirebase";
import React, { Suspense, useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import ReactDOM from "react-dom";
import "./index.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/500.css";
import "@fontsource/lato/400.css";
import { deferBeforeInstallPrompt } from "$utils/installPrompt";
const App = () => {
    const [configLoaded, setConfigLoaded] = useState(false);
    useEffect(() => {
        initializeFirebaseService().then(() => setConfigLoaded(true));
    }, []);
    const LazyAppRoutes = React.lazy(async () => {
        const { AppRoutes } = await import("./AppRoutes");
        return { default: AppRoutes };
    });
    return (React.createElement(React.StrictMode, null,
        React.createElement(Suspense, { fallback: React.createElement("div", null) }, configLoaded && React.createElement(LazyAppRoutes, null))));
};
export default hot(module)(App);
ReactDOM.render(React.createElement(App, null), document.getElementById("root"));
/**
 * For better DX service worker registration is disabled in development. If you need to work with service worker comment out NODE_ENV check
 */
if (process.env.NODE_ENV === "production") {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("sw.js").then(() => {
            console.log("Service Worker Registered");
        });
    }
    if (typeof window !== "undefined") {
        window.addEventListener("beforeinstallprompt", deferBeforeInstallPrompt);
    }
}
